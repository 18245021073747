import React from "react";
import "./PrivacyPolicy.scss";
import Footer from "../Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

function PrivacyPolicy() {
  return (
    <div id="presentation" className="privacy-policy-page">
      <NavBar />
      <div className="header-page">
        <img src="/img/background_card.png" alt="banner" />
        <h1>Politique de confidentialité</h1>
      </div>

      <div className="content-page">
        <blockquote>
          <h2><strong>1. Collecte de l’information</strong></h2>
          <p>Nous recueillons des informations lorsque vous vous inscrivez sur notre application, lorsque vous vous connectez à votre compte et / ou lorsque vous vous déconnectez. Les informations recueillies incluent votre nom, votre adresse e-mail.</p>
          <p>En outre, nous recevons et enregistrons automatiquement des informations à partir de votre ordinateur et navigateur, y compris votre adresse IP, vos logiciels et votre matériel, et la page que vous demandez.</p>
        </blockquote>

        <blockquote>
          <h2><strong>2. Utilisation des informations</strong></h2>
          <p>Toute les informations que nous recueillons auprès de vous peuvent être utilisées pour :</p>
          <ul>
            <li>Personnaliser votre expérience et répondre à vos besoins individuels</li>
            <li>Améliorer le service client et vos besoins de prise en charge</li>
            <li>Vous contacter par e-mail</li>
          </ul>
        </blockquote>

        <blockquote>
          <h2><strong>3. Confidentialité</strong></h2>
          <p>Nous sommes les seuls propriétaires des informations recueillies sur ce site. Vos informations personnelles ne seront pas vendues, échangées, transférées, ou données à une autre société pour n’importe quel raison, sans votre consentement, en dehors de ce qui est nécessaire pour répondre à une demande.</p>
        </blockquote>

        <blockquote>
          <h2><strong>4. Divulgation à des tiers</strong></h2>
          <p>Nous ne vendons, n’échangeons et ne transférons pas vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.</p>
          <p>Nous pensons qu’il est nécessaire de partager des informations afin d’enquêter, de prévenir ou de prendre des mesures concernant des activités illégales, fraudes présumées, situations impliquant des menaces potentielles à la sécurité physique de toute personne, violations de nos conditions d’utilisation, ou quand la loi nous y contraint.</p>
        </blockquote>

        <blockquote>
          <h2><strong>5. Protection des informations</strong></h2>
          <p>Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles. Nous utilisons un cryptage à la pointe de la technologie pour protéger les informations sensibles transmises en ligne. Nous protégeons également vos informations hors ligne. Seuls les employés qui ont besoin d’effectuer un travail spécifique (par exemple, la facturation ou le service à la clientèle) ont accès aux informations personnelles identifiables. Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables sont conservés dans un environnement sécurisé.</p>
          <p><strong>Est-ce que nous utilisons des cookies ?</strong></p>
          <p>Oui. Nos cookies améliorent l’accès à notre site et identifient les visiteurs réguliers. En outre, nos cookies améliorent l’expérience d’utilisateur grâce au suivi et au ciblage de ses intérêts. Cependant, cette utilisation des cookies n’est en aucune façon liée à des informations personnelles identifiables sur notre site.</p>
        </blockquote>

        <blockquote>
          <h2><strong>6. Consentement</strong></h2>
          <p>En utilisant notre site, vous consentez à notre politique de confidentialité.</p>
        </blockquote>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;

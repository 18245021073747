import React from "react";
import "./Cgu.scss";
import Footer from "../Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

function Cgu() {
  return (
    <div className="cgu-page">
      <NavBar />
      <div className="header-page">
        <img src="/img/background_card.png" alt="banner" />
        <h1>Conditions Générales d'Utilisation</h1>
      </div>

      <div className="content-page">
        <h2>Généralités</h2>
        <p>Axotools édite le site Internet dénommé «<strong>AXOTOOLS</strong> » accessible à l’adresse url&nbsp;: <a href="https://axotools.fr/">https://axotools.fr/</a>.</p>
        <p>Pour pouvoir créer ou rejoindre une structure E-sport, l'utilisateur s’engage au préalable à prendre connaissance des présentes conditions générales d'utilisation (ci- après dénommées « CGU ») et déclare qu'il les a expressément acceptées.</p>
        <p>Les présentes CGU ont pour but de déterminer les modalités d’utilisation du service et contenus proposés sur le Site. Les CGU en vigueur et applicables sont celles reproduites sur le Site à la date de consultation du Site ou d’utilisation de ses éléments. Les présentes CGU peuvent être modifiées à tout moment afin de les adapter notamment aux évolutions techniques et/ou légales. Ces modifications sont portées à la connaissance de l’internaute par leur simple mise en ligne. Il convient, dans ces conditions, de consulter régulièrement la dernière version des CGU.</p>
        <h2>Article 1 : Définitions</h2>
        <p>Dans les présentes CGU, les termes visés ci-dessous auront la signification suivante:</p>
        <p>•&nbsp;&nbsp;&nbsp; «&nbsp;<strong>Axotools&nbsp;»</strong> : un site internet présentant une application de gestion de structure E-sport.</p>
        <p>•&nbsp;&nbsp;&nbsp; <strong> «&nbsp;Membre (s)&nbsp;»</strong> : la/les personne(s) physique(s) qui utilise l'application Axotools.</p>
        <p>•&nbsp;&nbsp;&nbsp; «&nbsp;<strong>Visiteur(s)&nbsp;»</strong>: la/les personne(s) physique(s) qui consulte(nt) le Site.</p>
        <h2>Article 2 - Pré-requis</h2>
        <p>L’accès au Site s’effectue, 24h/24h, directement par internet à partir de l’adresse URL suivante&nbsp;: <a href="https://axotools.fr/">https://axotools.fr/</a>;</p>
        <ul>
          <li>à partir d’un ordinateur (micro-ordinateur PC/Mac/Linux etc) muni d’une connexion à internet&nbsp;;</li>
          <li>ou à partir d’un téléphone mobile /tablette numérique doté d’une connexion à internet ou doté d’un réseau 3G, 4G etc.</li>
        </ul>
        <p>Les équipements d’accès précités ainsi que les frais de connexion restent à la charge exclusive de de l’utilisateur.</p>
        <h2>Article 3 - Présentation</h2>
        <p>Une fois inscrit sur l'application selon les modalités définies à l’article 4 ci-après, Axotools met à la disposition des membres une interface d'administration permettant de créer et de rejoindre une structure E-sport.</p>
        <p>Chaque Structure est destiné à mettre en avant sur le site internet Axotools.</p>
        <h2>Article 4 - Modalités d'inscription</h2>
        <p>Toute création d'une structure est conditionnée par l'ouverture préalable par le membre d'un compte personnel accessible à l’aide&nbsp;:</p>
        <ul>
          <li>d’un identifiant personnel (Pseudo de l'utilisateur)</li>
          <li>et d’un mot de passe choisit par l'utilisateur.</li>
        </ul>
        <p>L'utilisateur s'engage à cet effet, à dument compléter le formulaire d'inscription permettant l'ouverture de son compte et à le mettre à jour régulièrement, et ce, en renseignant les informations suivantes :</p>
        <ul>
          <li>&nbsp;Pseudo*</li>
          <li>&nbsp;Prénom*</li>
          <li>&nbsp;Nom*</li>
          <li>&nbsp;adresse électronique (valide)*</li>
          <li>&nbsp;mot de passe*</li>
          <li>&nbsp;Photo/ Image (Avatar)</li>
        </ul>
        <p>Les informations comportant un astérisque sont obligatoires.</p>
        <p>Etant entendu que :</p>
        <p>- &nbsp;&nbsp; Le pseudo de l’utilisateur apparaitra sur l'application et permettra d’identifier un membre du'une structure du l'application.</p>
        <p>-&nbsp;&nbsp;&nbsp; l’utilisateur s’engage à communiquer des informations fiables permettant de le contacter en cas de besoin&nbsp;;</p>
        <p>- &nbsp;&nbsp; l’utilisateur est seul responsable de la confidentialité ainsi que des actions réalisées avec son mot de passe et/ou son compte&nbsp;;</p>
        <p>- &nbsp;&nbsp; toute utilisation, perte, ou détournement de l’identifiant et/ou du mot de passe de l’utilisateur relèvent de sa seule et entière responsabilité&nbsp;;</p>
        <p>- &nbsp;&nbsp; dans l’hypothèse où l’utilisateur fournirait des informations fausses, inexactes, trompeuses ou de nature à induire en erreur, Axotools se réserve le droit de suspendre ou supprimer le compte, sans délai, et de refuser l’accès, à titre temporaire ou définitif au Site (dans les conditions prévues par l’article 10&nbsp; ci-dessous).</p>
        <p>La création du compte et la creation de structure sur celui-ci valent donc acceptation des présentes CGU par l’utilisateur.</p>
        <h2>Article 5 - Obligations à la charge des utilisateurs</h2>
        <p>L'utilisateur s’engage à s’assurer, pour des questions de sécurité, qu'il a bien quitté son compte à la fin de chaque session d'utilisation en cliquant sur le lien « déconnexion » se trouvant sur le Site.</p>
        <p>Il est rappelé que l'utilisateur s'engagent notamment à ne pas :</p>
        <p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; violer, de façon intentionnée ou non, aucune loi en vigueur et ce que ce soit au niveau local, régional, national ou international notamment ;</p>
        <p>- toute disposition relative au respect de la vie privée, au droit de la propriété intellectuelle, au droit de la presse, au droit des brevets, au droit des marques ainsi qu'au droit à l'image ;</p>
        <p>- la législation applicable en matière de fraude informatique ;</p>
        <p>- les règles d'ordre public, notamment la règlementation applicable en matière de contenu pornographique, et pédophile ;</p>
        <p>- la législation applicable au mineur ;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; se substituer à une personne morale ou à une personne physique&nbsp;;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; diffuser de l’information délibérément fausse dans le but ou non de nuire à autrui ;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; télécharger, poster, envoyer des e-mails ou transmettre de quelque façon que ce soit tout contenu qu'il n'a pas le droit de transmettre en dehors du cadre légal ;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; télécharger, poster, envoyer des e-mails ou transmettre de quelque façon que ce soit tout contenu faisant l'objet de clauses de confidentialité ;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; télécharger, poster, envoyer des e-mails ou transmettre de quelque façon que ce soit tout contenu qui contiendrait des virus ;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; harceler quiconque et de quelque manière que ce soit ;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; faire la promotion ou fournir des informations à propos d'activité illégales, violentes ou incitant à la violence envers des individus ou des groupes de personnes ;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; proposer à la vente tout objet, bien ou service qui viole les lois ou pour lequel l'utilisateur ne disposerait pas de tous les droits lui permettant de l'offrir ou de le vendre ;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; faire de la publicité pour une marque quelconque, sans l'accord préalable de Axotools&nbsp;;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; accéder au compte d'un autre membre et utiliser et/ou modifier n'importe quel contenu/commentaires ou tout autre information d'un autre utilisateur;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; se comporter de façon à porter atteinte à l'image et/ou à la réputation de Axotools;</p>
        <p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; à ne pas détourner de son objet, à savoir la création et la publication d'une Structure, les outils informatiques ( et notamment l’interface) mis à sa disposition par Axotools&nbsp;;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; manipuler l'identifiant et mot de passe dans le but de dissimuler l'origine de toute information ou contenu transmis via le Service ;</p>
        <p>• &nbsp;&nbsp;&nbsp;&nbsp; accéder au compte d'un autre membre et à ne pas utiliser et/ou modifier n'importe quel contenu/contributions ou tout autre information d'un autre utilisateur&nbsp;;</p>
        <p>L’utilisateur s'engage par ailleurs à ne pas faire la promotion, dans sa Structure, de sites internet contrevenant aux présentes CGU, et notamment à ne pas insérer, dans son&nbsp; contenu de liens hypertextes renvoyant vers des sites contrevenant aux présentes CGU et/ou plus généralement à la législation en vigueur.</p>
        <p>L'utilisateur et/ou le Visteur s'engagent à :</p>
        <p>- informer sans délai Axotools de tout contenu à caractère illicite, dont il pourrait avoir connaissance ;</p>
        <p>- informer sans délai Axotools en cas de plainte ou actions de tiers concernant le contenu d’une Structure;</p>
        <p>- répondre dans les meilleurs délais à toute demande d'information de la part d'Axotools en cas de litige.</p>
        <h2>Article 6 - Propriété intellectuelle</h2>
        <p>Tous les éléments du Site, textes, illustrations, sons et images fixes ou animées reproduits sur le Site ainsi que les signes distinctifs d’Axotools (marque, logos etc.) sont protégés par le droit d'auteur ainsi qu'au titre de la propriété intellectuelle, et ce dans le monde entier.</p>
        <p>A ce titre et conformément aux dispositions du Code de la Propriété intellectuelle :</p>
        <ul>
          <li>toute reproduction totale ou partielle du site est strictement interdite&nbsp;;</li>
          <li>l’utilisateur s'engage à ne pas modifier, copier, re-publier, transmettre, vendre ou distribuer de quelque manière que ce soit, ledit Site ou les éléments qui le composent.</li>
        </ul>
        <p>Axotools demeure pleinement propriétaire des éléments corporels et incorporels de l'interface d'administration mise à disposition des membres afin qu'ils puissent créer et rejoindre une structure E-sport.</p>
        <p>Aucun droit de propriété de tout ou partie de l'interface d'administration ne peut à ce titre être transféré aux utilisateurs.</p>
        <p>A ce titre les utilisateurs s'engagent à ne pas :</p>
        <ul>
          <li>procéder à un quelconque « reverse engineering » de ladite interface ;</li>
          <li>decompiler ou désassembler l'interface d'administration ;</li>
          <li>modifier ou créer des programmes développés sur tout ou partie de l'interface d'administration;</li>
          <li>&nbsp;enlever ou supprimer toutes informations propriétaires sur le Site;</li>
          <li>revendre, louer, sous louer ou transférer de quelque manière que ce soit tout droit lié à l'interface d'administration et/ou au Site.</li>
        </ul>
        <h2>Article 7 - Abonnement</h2>
        <p>L'application est utilisable dans une version gratuite pour une durée limitée.</p>
        <p>Lors de la création d'une structure, un abonnement est requis.</p>
        <p>Les membres qui rejoignent une structure n'ont pas besoin d'avoir un abonnement.</p>
        <p>Tout abonnement expiré bloquera l'utilisation de l'application.</p>
        <p>Pour les abonnements, le service Stripe est utilisé afin de réalisation les transactions. Pour en savoir plus sur la plateform Stripe, veuillez consulter cette page : <a href="https://stripe.com/fr/privacy">https://stripe.com/fr/privacy</a></p>
        <h2>Article 8 - Résiliation</h2>
        <p>Axotools se réserve la faculté de résilier immédiatement et sans mise en demeure préalable le compte de tout utilisateur qui contreviendrait aux présentes CGU, aux lois et règlements ou encore sur demande de retrait judiciaire ou sur notification motivée de tout tiers, sans que l'utilisateur ne puisse lui demander une quelconque indemnité ni un quelconque remboursement.</p>
        <p>La résiliation d'un compte implique :</p>
        <ul>
          <li>la suppression de l'utilisateur sans qu'aucune responsabilité ne soit opposée à Axotools;</li>
          <li>l'interruption de l'accès à toutes les fonctionnalités du Site;</li>
          <li>l'incapacité pour l'utilisateur d'utiliser son mot de passe ;</li>
          <li>l'interdiction de toute utilisation future du Site.</li>
        </ul>
        <p>L'utilisateur reconnaît et accepte qu'Axotools puisse néanmoins conserver le contenu de la structure incriminé supprimé et les informations personnelles le concernant, afin de les communiquer, le cas échéant, selon les exigences légales et règlementaires.</p>
        <p>L’utilisateur a la possibilité de supprimer son compte à tout moment, sans aucune justification, en notifiant sa volonté à Axotools en adressant un courrier électronique à l’adresse axotools@gmail.com. Dans cette hypothèse, Axotools s’engage à supprimer, sans délai, le compte de l’utilisateur.</p>
        <h2>Article 9 - Droit applicable - Compétence</h2>
        <p>Les présentes CGU sont régies par le droit français.</p>
        <h2>Article 10 - Identification</h2>
        <p><strong>Le Site Axotools est édité par Axotools </strong></p>
        <p>Directeur de la publication&nbsp;: Maxime Duroyon</p>
        <p>Dernière mise à jour&nbsp;: le 04.07.22</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
      <Footer />
    </div>
  );
}

export default Cgu;

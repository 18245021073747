import * as React from "react";
import "./Welcome.scss";
import animationData from "../../assets/img/animation-welcome.json";

export default function Welcome() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div id="welcome" className="welcome">
      <div className="rond"></div>
      <div className="rond-2"></div>
      <div className="text-presentation">
        <div>
          <h1 className="first-text">
            Gérez votre structure <br></br>
            <span className="text-bold">e-sport en ligne</span>
          </h1>
          <span className="second-text">
            Gérer votre <span className="text-bold">structure</span> en ligne
            c'est désormais <span className="text-bold">possible</span> avec
            <span className="text-bold"> Axotools</span>
          </span>
          <div className="button">
            <a href="https://app.axotools.fr">Essayer maintenant</a>
            <a href="#mobile">Télécharger</a>
          </div>
        </div>
      </div>
      <div className="animation">
        <img src="img/image_first_page_axotools.png" alt="" />
      </div>
      {/* <div className="arrow-circle">
        <a href="google.com">
          <ArrowCircleDownIcon />
        </a>
      </div> */}
    </div>
  );
}

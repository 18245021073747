import React, {useEffect, useState} from "react";
import "./Structure.scss";
import StructureService from "../../services/structure-service";
import {formatDate} from "../../utils/date";

function Structure() {
  const [structures, setStructures] = useState(null);

  const fetchStructures = async () => {
    const structureService = new StructureService();
    await structureService.getAll().then(res => {
      const data = res.slice(0,9).map(structure => {
        return {
          name: structure.name,
          url: structure?.urlSite,
          img: structure.mediaFiles.length > 0 ? process.env.REACT_APP_API_URL + structure.mediaFiles[0].fileUrl : null,
          created_at: structure.createdAt ? formatDate(structure.createdAt) : null,
        };
      });
      data && setStructures(data);
    });
  }

  useEffect(() => {
    fetchStructures();
  }, []);

  return (
    <div id="structures" className={structures && structures.length > 0 ? "section" : ""}>
      {structures && structures.length ? <h2>
        Ils nous font <span className="font-bold">confiance</span>
      </h2> : ''}
      <div className="wrapper">
        {structures && structures.length ? structures.map((value, index) => {
          return (
            <a
              target="_blank"
              href={value.url ? value.url : ""}
              className={`card-structure ${
                index % 2 === 0 ? "colored" : "second-colored"
              }`}
              key={index}
            >
              <div className="content-card">
                <div className="card-created">
                  <span>Depuis {value.created_at}</span>
                </div>
                <div className="card-logo">
                  <div className="particle particle-1"></div>
                  <div className="particle particle-2"></div>
                  <div className="particle particle-3"></div>
                  <img src={value.img ?? '/img/logo_axotools.png'} alt="" />
                </div>
              </div>
              <div className="card-name">
                <span>{value.name}</span>
              </div>
            </a>
          );
        }) : ''}
      </div>
    </div>
  );
}

export default Structure;

import React from "react";
import "./LegalNotice.scss";
import Footer from "../Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

function LegalNotice() {
  return (
    <div className="legal-notice-page">
      <NavBar />
      <div className="header-page">
        <img src="/img/background_card.png" alt="banner" />
        <h1>Mentions légales</h1>
      </div>

      <div className="content-page">
        <h2><strong>MENTIONS LEGALES</strong></h2>
        <p>Le Site internet AXOTOOLS&nbsp; est édité par Axotools&nbsp;:</p>
        <p><strong>Directeur de la Publication</strong>&nbsp;: Maxime Duroyon</p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>HEBERGEMENT DU SITE </strong></p>
        <p>Le Site internet AXOTOOLS&nbsp;est hébergé par 1&1 IONOS&nbsp;:</p>
        <p><strong>Siège social&nbsp;:</strong> Montabaur – ALLEMAGNE</p>
        <p><a>&nbsp;</a></p>
        <p><strong>Propriété intellectuelle</strong></p>
        <p>Tous les contenus composant le Site AXOTOOLS et les signes distinctifs (logo, marques etc.) sont protégés par le droit d'auteur et le droit de la propriété intellectuelle, et ce dans le monde entier.</p>
        <p>À ce titre et conformément aux dispositions du Code de la Propriété intellectuelle :</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- toute reproduction totale ou partielle des contenus, du Site ou des signes distinctifs est strictement interdite,</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- il est interdit de modifier, copier, re-publier, transmettre, vendre ou distribuer de quelque manière que ce soit, lescontenus, le Site ou les signes distinctifs.</p>
        <p>Le non-respect d’une des dispositions précitées expose le contrevenant, et toutes personnes responsables, à des actions judiciaires sanctionnées civilement et pénalement au titre de la contrefaçon.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
      <Footer />
    </div>
  );
}

export default LegalNotice;

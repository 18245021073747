import React, {useState} from "react";
import "./Presentation.scss";
import {getCookieConsent, getCookieConsentYoutube, setCookieConsentYoutube} from "../../utils/cookie";

function Structure() {
  const [youtubeCookie, setYoutubeCookie] = useState(getCookieConsentYoutube());

  const onActiveYoutube = () => {
    setYoutubeCookie(true);
    setCookieConsentYoutube(true);
  }

  return (
    <div id="presentation" className="section-presentation">
      <h2>
        Présentation de notre <span className="font-bold">solution</span>
      </h2>
      <div>
        {youtubeCookie ? <iframe
            width="700"
            height="400"
            src="https://www.youtube.com/embed/tVzYXhg_j2k"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe> : <div className="empty-video">
          {getCookieConsent() ? <span onClick={() => onActiveYoutube()}>Activer Youtube</span> : <p>Accepter les cookies pour voir la vidéo.</p>}
          </div>}
      </div>
    </div>
  );
}

export default Structure;

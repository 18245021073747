import React from "react";
import "./Homepage.scss";
import Welcome from "../Welcome/Welcome";
import Presentation from "../Presentation/Presentation";
import Structure from "../Structures/Structure";
import Prices from "../Prices/Prices";
import Mobile from "../Mobile/Mobile";
import Footer from "../Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

function Homepage() {
  return (
    <div className="homepage">
      <NavBar />
      <Welcome />
      <Presentation />
      <Structure />
      <Prices />
      <Mobile />
      <Footer />
    </div>
  );
}

export default Homepage;

import axios from "axios";
import { getBaseUrl } from "./settings-service";

export default class FormuleService {
	getAll() {
		return axios
			.get(`${getBaseUrl()}formules?trialPeriod%5Blte%5D=30`)
			.then(({ data }) => data["hydra:member"]);
	}
}

import React, {useEffect, useState} from "react";
import "./Prices.scss";
import CheckIcon from "@mui/icons-material/Check";
import FormuleService from "../../services/formule-service";

function Prices() {
  const [formules, setFormules] = useState();

  const fetchFormules = async () => {
    const formuleService = new FormuleService();
    await formuleService.getAll().then(res => {
      const data = res.map(formule => {
        return {
          name: formule.name,
          price: formule.price,
          possibilities: [
            formule.trialPeriod + " jours",
            formule.maxMembers + " personnes maximum",
            "Full fonctionnalités",
            "Full Support",
          ],
        };
      });
      data && setFormules(data);
    });
  }

  useEffect(() => {
    fetchFormules();
  }, []);

  return (
    <div id="prices" className={formules && "section"}>
      {formules && <h2>
        Nos <span className="font-bold">formules</span>
      </h2>}
      <div className="formules">
        {formules && formules.map((value, index) => {
          return (
            <div className="card" key={index}>
              <div className={`bar-color bar-color-${index}`}></div>
              <div className="part-sup">
                <span className="categ">{value.name}</span>
                <span className="price">{value.price === 0 ? 'Gratuit' : value.price + '€'}</span>
                <div className={`button  color-${index}`}>
                  <a href="https://app.axotools.fr">Choisir</a>
                </div>
              </div>
              <div className="part-inf">
                {value.possibilities.map((secondValue, index) => {
                  return (
                    <div key={index}>
                      <CheckIcon />
                      <span>{secondValue}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Prices;

import axios from "axios";
import { getBaseUrl } from "./settings-service";

export default class StructureService {
	getAll() {
		return axios
			.get(`${getBaseUrl()}all_structures?isActive=true`)
			.then(({ data }) => data["hydra:member"]);
	}
}

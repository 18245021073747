import "./App.css";
import "./assets/style/main.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./containers/Homepage/Homepage";
import React from "react";
import Cgu from "./containers/Cgu/Cgu";
import LegalNotice from "./containers/LegalNotice/LegalNotice";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import Contact from "./containers/Contact/Contact";
import Cookie from "./containers/Cookie/Cookie";
import NotFoundPage from "./containers/forbidden/NotFoundPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/">
            <Route index element={<Homepage />} />
            <Route path="contact" element={<Contact />} />
            <Route path="conditions-generales-utilisation" element={<Cgu />} />
            <Route path="mentions-legales" element={<LegalNotice />} />
            <Route path="politique-confidentialite" element={<PrivacyPolicy />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <Cookie />
    </div>
  );
}

export default App;

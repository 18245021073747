import React from "react";
import "./Footer.scss";
import {useNavigate} from "react-router-dom";

function Footer() {
  let navigate = useNavigate();

  const navigateTo = (route) => {
    navigate(route);
  }

  const onOpenModalCookie = () => {
    const modal = document.querySelector('.cookie_modal');
    modal && modal.classList.add('show');
  }

  return (
    <footer>
      <span>© 2021 - Axotools</span>
      <span>|</span>
      <span className="link" onClick={() => navigateTo("/conditions-generales-utilisation")}>CGU</span>
      <span>|</span>
      <span className="link" onClick={() => navigateTo("/mentions-legales")}>Mentions légales</span>
      <span>|</span>
      <span className="link" onClick={() => navigateTo("/politique-confidentialite")}>Politique de confidentialité</span>
      <span>|</span>
      <span className="link" onClick={() => onOpenModalCookie()}>Gérer les cookies</span>
    </footer>
  );
}

export default Footer;

import "./Cookie.scss";
import { useState } from "react";
import {
	getCookieConsent,
	getCookieConsentAnalytics,
	getCookieConsentYoutube,
	setCookieConsent,
	setCookieConsentAnalytics,
	setCookieConsentYoutube
} from "../../utils/cookie";

export default function Cookie() {
	const [showCookie, setShowCookie] = useState(!getCookieConsent());

	const onClickCookie = (analytics, youtube) => {
		setShowCookie(false);
		setCookieConsent(analytics, youtube);
	}

	const onCloseModal = (e) => {
		const modal = e.target?.closest('.cookie_modal');
		modal && modal.classList.remove('show');
		window.location.reload();
	}

	return (
		<div className="cookie">
			<div className={'cookie_modal' + (getCookieConsent ? '' : ' show')}>
				<div className="cookie_modal-container">
					<span className="close-modal" onClick={(e) => onCloseModal(e)}>x</span>
					<p className="title">Vos paramètres de cookies</p>
					<p className="description">Nous utilisons des cookies pour le fonctionnement de ce site, pour améliorer son utilisation, personnaliser votre expérience et réaliser des statistiques de visite. Vous pouvez gérer les paramètres et choisir d’accepter ou non certains cookies durant votre navigation.</p>
					<div className="cookie_modal-categ">
						<div>
							<span className="title">Tous les services</span>
							<span className="button-accept" onClick={() => {
								setCookieConsent(true, true);
								document.querySelector('.cookie_modal')?.classList.remove('show');
							}}>Tout accepter</span>
							<span className="button-reject" onClick={() => {
								setCookieConsent(false, false);
								document.querySelector('.cookie_modal')?.classList.remove('show');
							}}>Tout refuser</span>
						</div>
						<div>
							<span className="title">Mesure d'audience</span>
							<span className="description">Les services de mesure d'audience permettent de générer des statistiques de fréquentation utiles pour améliorer le site.</span>
							<span className={'button-accept' + (getCookieConsentAnalytics() ? ' checked' : '')} onClick={(e) => {
								setCookieConsentAnalytics(true);
								e.target?.classList.add('checked');
								e.target?.nextElementSibling.classList.remove('checked');
							}}>Autoriser</span>
							<span className={'button-reject' + (!getCookieConsentAnalytics() ? ' checked' : '')} onClick={(e) => {
								setCookieConsentAnalytics(false);
								e.target?.classList.add('checked');
								e.target?.previousElementSibling.classList.remove('checked');
							}}>Refuser</span>
						</div>
						<div>
							<span className="title">Video externe (Youtube)</span>
							<span className="description">Les services de externe de vidéo permet une meilleure intégration des visuels dans le site internet.</span>
							<span className={'button-accept' + (getCookieConsentYoutube() ? ' checked' : '')} onClick={(e) => {
								setCookieConsentYoutube(true);
								e.target?.classList.add('checked');
								e.target?.nextElementSibling.classList.remove('checked');
							}}>Autoriser</span>
							<span className={'button-reject' + (!getCookieConsentYoutube() ? ' checked' : '')} onClick={(e) => {
								setCookieConsentYoutube(false);
								e.target?.classList.add('checked');
								e.target?.previousElementSibling.classList.remove('checked');
							}}>Refuser</span>
						</div>
					</div>
				</div>
			</div>
			{showCookie && <div className="cookie_consent">
				<span className="title">Vos paramètres de cookies</span>
				<p className="description">Nous utilisons des cookies pour le fonctionnement de ce site, pour améliorer son utilisation, personnaliser votre expérience et réaliser des statistiques de visite. Vous pouvez gérer les paramètres et choisir d’accepter ou non certains cookies durant votre navigation.</p>
				<span className="button-accept" onClick={() => onClickCookie(true, true)}>Tout accepter</span>
				<span className="button-reject" onClick={() => onClickCookie(false, false)}>Tout refuser</span>
			</div>}
		</div>
	);
}

import React, { useState } from "react";
import "./Mobile.scss";

function Mobile() {
  const [activeMessage, setActiveMessage] = useState(false);

  const displayMessage = () => {
    setActiveMessage(true);

    setTimeout(() => {
      setActiveMessage(false);
    }, 6000)
  }

  return (
    <div id="mobile" className="section-mobile section">
      <h2>
        <span className="font-bold">Télécharge</span> notre application
      </h2>
      <div className="mobile">
        <div className="logos" onClick={displayMessage}>
          <a>
            <img src="img/mobile/android_logo.png" alt="" />
          </a>
          <a>
            <img src="img/mobile/ios_logo.png" alt="" />
          </a>
        </div>
      </div>
      <div className={"mobile-message" + (activeMessage ? ' active' : '')}>Notre application mobile est en cours de création, merci pour votre patience.</div>
    </div>
  );
}

export default Mobile;

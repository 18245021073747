import React from "react";
import "./NotFoundPage.scss";
import {useNavigate} from "react-router-dom";

function NotFoundPage() {
	let navigate = useNavigate();

	return (
		<div className="notfound-page">
			<img className="background" src="/img/background_404.png" alt="" />
			<img className="logo" src="/img/axotools_404.png" alt="" />
			<span className="title">Je sais pas si tu cherches un easter egg, mais j'ai rien à te proposer ici...</span>
			<span className="button" onClick={() => navigate(`/`)}>Revenir en lieu sûr</span>
		</div>
	);
}

export default NotFoundPage;

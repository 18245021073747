import "./NavBar.scss";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedinIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import {useNavigate} from "react-router-dom";

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  let navigate = useNavigate();

  const navigateTo = (route) => {
    navigate(route);
  }

  return (
    <>
      <nav className="nav">
        <div>
          <a href="#welcome" onClick={() => navigateTo("/#welcome")}>
            <img src="img/logoAxotoolsNavBarGreen.png" alt="" />
          </a>
        </div>
        <div className="networks">
          <div>
            <a className="twitter" href="https://mobile.twitter.com/axotools" target="_blank">
              <TwitterIcon />
            </a>
            <a className="instagram" href="https://www.instagram.com/axotools" target="_blank">
              <InstagramIcon />
            </a>
            <a className="linkedin" href="https://www.linkedin.com/company/axotools" target="_blank">
              <LinkedinIcon />
            </a>
          </div>
        </div>
        <ul className="menu">
          <li>
            <a href="#presentation" onClick={() => navigateTo("/#presentation")}>présentation</a>
          </li>
          <li>
            <a href="#structures" onClick={() => navigateTo("/#structures")}>structures</a>
          </li>
          <li>
            <a href="" onClick={() => navigateTo("/contact")}>contact</a>
          </li>
        </ul>
      </nav>

      <nav className="nav-mobile">
        <div className="networks">
          <div>
            <a className="twitter" href="https://mobile.twitter.com/axotools" target="_blank">
              <TwitterIcon />
            </a>
            <a className="instagram" href="https://www.instagram.com/axotools" target="_blank">
              <InstagramIcon />
            </a>
            <a className="linkedin" href="https://www.linkedin.com/company/axotools" target="_blank">
              <LinkedinIcon />
            </a>
          </div>
        </div>
        <div>
          <a href="#welcome">
            <img src="img/logoAxotoolsNavBarGreen.png" alt="" />
          </a>
        </div>
        <div className="mobile-menu" onClick={() => setIsOpen(!isOpen)}>
          <a className="logo-menu">
            <MenuIcon />
          </a>
        </div>
        {isOpen && (
          <div>
            <div
              className="background-menu"
              onClick={() => setIsOpen(!isOpen)}
            ></div>
            <div className="mobile-nav">
              <div className="mobile-menu" onClick={() => setIsOpen(!isOpen)}>
                <a className="logo-menu">
                  <CloseIcon />
                </a>
              </div>
              <ul className="menu-mobile">
                <li>
                  <a onClick={() => setIsOpen(!isOpen)} href="#presentation">
                    présentation
                  </a>
                </li>
                <li>
                  <a onClick={() => setIsOpen(!isOpen)} href="#structures">
                    structures
                  </a>
                </li>
                <li>
                  <a onClick={() => setIsOpen(!isOpen)} href="">
                    contact
                  </a>
                </li>
              </ul>
              <div className="mobile-networks">
                <div>
                  <a className="twitter" href="https://mobile.twitter.com/axotools" target="_blank">
                    <TwitterIcon />
                  </a>
                  <a className="instagram" href="https://www.instagram.com/axotools" target="_blank">
                    <InstagramIcon />
                  </a>
                  <a className="linkedin" href="https://www.linkedin.com/company/axotools" target="_blank">
                    <LinkedinIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </nav>
    </>
  );
}

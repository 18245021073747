export function getCookie(cookieName) {
  let cookie = {};
  document.cookie.split(";").forEach(function (el) {
    let [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
}

export function setCookie(name, value, seconds) {
  let values = name + "=" + value;
  let path = "path=/";
  let time = "max-age=" + seconds;

  document.cookie = values + ";" + path + ";" + time + ";";
}

export const getCookieConsent = () => {
  return getCookie('cookie_consent') ? JSON.parse(getCookie('cookie_consent')) : null;
}

export const setCookieConsent = (analytics, youtube) => {
  setCookie('cookie_consent', JSON.stringify({
    analytics: analytics,
    youtube: youtube
  }));
  window.location.reload();
}

export const setCookieConsentAnalytics = (analytics) => {
  const youtube = getCookieConsent()?.youtube ?? false
  setCookie('cookie_consent', JSON.stringify({
    analytics: analytics,
    youtube: youtube
  }));
  window.location.reload();
}

export const setCookieConsentYoutube = (youtube) => {
  const analytics = getCookieConsent()?.analytics ?? false
  setCookie('cookie_consent', JSON.stringify({
    analytics: analytics,
    youtube: youtube
  }));
  window.location.reload();
}

export const getCookieConsentAnalytics = () => {
  return getCookieConsent()?.analytics ?? false
}

export const getCookieConsentYoutube = () => {
  return getCookieConsent()?.youtube ?? false
}

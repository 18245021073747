import React from "react";
import "./Contact.scss";
import Footer from "../Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

function Contact() {
  return (
    <div className="legal-notice-page">
      <NavBar />
      <div className="header-page">
        <img src="/img/background_card.png" alt="banner" />
        <h1>Contact</h1>
      </div>

      <div className="content-page">
        <h2><strong>INFORMATIONS</strong></h2>
        <p>Lien du site internet:&nbsp; <a href="https://axotools.fr">https://axotools.fr</a></p>
        <p>Lien de l'application:&nbsp; <a href="https://app.axotools.fr">https://app.axotools.fr</a></p>
        <p>&nbsp;</p>
        <p>Adresse email:&nbsp; <a href="mailto:axotools@gmail.com">axotools@gmail.com</a>&nbsp;:</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
